import React, { useState, useEffect, useRef } from 'react';
import styles from './EmojiPicker.module.scss';

interface Emojis {
  [description: string]: string;
}

interface Props {
  onPick: (emoji: string) => void;
  close: () => void;
}

export default function EmojiPicker(props: Props) {
  const [emojis, setEmojis] = useState<Emojis>({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const selfRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (selfRef.current && !selfRef.current.contains(event.target as Node)) {
      props.close();
    }
  };

  useEffect(() => {
    // Load emojis from the emojis.json file
    fetch('/emojis.json')
      .then((response) => response.json())
      .then((data) => setEmojis(data))
      .catch((error) => console.error('Error loading emojis:', error));
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  // Filter emojis based on the search term
  const filteredEmojis = Object.entries(emojis).filter(([description, emoji]) =>
    description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.emojiPicker} ref={selfRef}>
      <input
        type="text"
        placeholder="Search emojis..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.searchBar}
      />
      <div className={styles.emojiGrid}>
        {filteredEmojis.map(([description, emoji]) => (
          <div
            key={description}
            className={styles.emojiItem}
            onClick={e => {
              e.preventDefault();
              props.close();
              props.onPick(emoji);
            }}>
            {emoji}
          </div>
        ))}
      </div>
    </div>
  );
};
